<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="扫码收款" right-text="使用说明" @click-right="inductionDialog = true" />
    </template>
    <div class="v-upload" v-if="!loading">
      <acquire-progress-status v-if="isFailed"></acquire-progress-status>
      <van-cell @click="accountTypeDialog = true"
                title="账户类型"
                is-link
                clickable
                :value="formData.accountType.value"
      ></van-cell>
      <div class="cell-title"><span class="text-color somber">上传资料</span> <span class="text-color gray">（{{ noticeText }}）</span>
      </div>
      <div class="upload-panel">
        <div class="upload-item">
          <uploader returnDataFormat="string" v-model="formData.IdentityCardFrontPic" />
          <div class="item--title pt-10">身份证正面</div>
        </div>
        <div class="upload-item">
          <uploader returnDataFormat="string" v-model="formData.IdentityCardBackPic" />
          <div class="item--title pt-10">身份证反面</div>
        </div>
        <div class="upload-item">
          <uploader returnDataFormat="string" v-model="formData.BizLicensePic" />
          <div class="item--title pt-10">营业执照</div>
        </div>
        <div class="upload-item">
          <uploader returnDataFormat="string" v-model="formData.ShopInsidePic" />
          <div class="item--title pt-10">店铺内景照</div>
        </div>
        <div class="upload-item">
          <uploader returnDataFormat="string" v-model="formData.BankCardPic" />
          <div class="item--title pt-10">{{ bankNameRemind }}</div>
        </div>
        <div class="upload-item">
          <uploader returnDataFormat="string" v-model="formData.ProtocolIndexPic" />
          <div class="item--title pt-10">门头照</div>
        </div>
      </div>
      <!-- 其它信息 -->
      <van-cell-group>
        <van-cell clickable
                  @click="areaDialog = true"
                  is-link
                  :value="showAddressValue"
                  :title="addressTitle"
        ></van-cell>
        <van-field v-model="formData.telephone"
                   type="tel"
                   clearable
                   :placeholder="telephonePlaceholder"
                   input-align="right"
                   label="负责人手机"
        />
        <van-field v-model="formData.email"
                   type="text"
                   clearable
                   input-align="right"
                   :placeholder="emailPlaceholder"
                   label="电子邮箱"
        />
      </van-cell-group>
    </div>
    <template v-slot:footer>
      <div style="padding: 15px;">
        <van-button color="#E4AF79" block @click="Submit">提交</van-button>
      </div>
    </template>
    <template v-slot:other>
      <van-popup v-model="accountTypeDialog" position="bottom">
        <van-picker :columns="formSelectOptions.accountType"
                    show-toolbar
                    value-key="label"
                    @confirm="handleConfirm"
                    @cancel="accountTypeDialog = false"
        />
      </van-popup>
      <!-- 省市选择器 -->
      <van-popup v-model="areaDialog" position="bottom">
        <van-area :value="areaCode"
                  @confirm="handleAreaConfirm"
                  :area-list="areaList"
                  :title="addressTitle"
        />
      </van-popup>
      <!-- 使用说明 -->
      <van-dialog v-model="inductionDialog" title="使用说明">
        <div class="content">
          <h1 class="title">1. 次日自动到账</h1>
          <p class="content">免去手动提现的繁琐</p>
          <h1 class="title">2. 交易费率0.35%</h1>
          <p class="content">远低于市场费率</p>
          <h1 class="title">3. 精准对账</h1>
          <p class="content">资金流水报表、每日对帐单</p>
          <h1 class="title">4. 添加收款专员微信 <span class="text-color orange">syzjkf05</span></h1>
          <p class="content">时时获取帮助</p>
        </div>
      </van-dialog>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import FormModel from '@/model/acquire-intro/upload/form'
import AcquireProgressStatus from '../modules/acquire-process'
import Uploader from '@/components/uploader'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import areaList from '@/utils/area'
import { Cell, CellGroup, Picker, Button, Popup, Area, Toast, Dialog, Field } from 'vant'

export default {
  name: 'AcquireInfoUploadInfo',
  data () {
    const formModel = new FormModel()
    const enumObj = {
      createForm: 1,
      recreateForm: 5
    }
    return {
      areaDialog: false,
      areaList,
      loading: false,
      accountTypeDialog: false,
      imgUrl: '',
      enumObj,
      formModel,
      formData: formModel.formData,
      formSelectOptions: formModel.formSelectOptions,
      point: true,
      step: 1,
      inductionDialog: false, // 使用说明
      telephonePlaceholder: '店铺实际经营管理者手机',
      emailPlaceholder: '接收银行信息(重要)',
      showNavbar: false,
      showDialog: false
    }
  },
  components: {
    PageView,
    NavBar,
    Uploader,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    AcquireProgressStatus
  },
  async created () {
    this.loading = true
    await this.formModel.loadHavePostForm() // 加载表单数据
    this.loading = false
    if (navigator.userAgent.match(/i200SYZJ/i)) this.showNavbar = true
  },
  mounted () {
    this.currentStep()
  },
  beforeDestroy () {
  },
  methods: {
    confirmBaseInfo (data) {
      if (!this.testNotNull(data.IdentityCardFrontPic)) {
        Toast('请上传身份证正面')
        return false
      }
      if (!this.testNotNull(data.IdentityCardBackPic)) {
        Toast('请上传身份证反面')
        return false
      }
      if (!this.testNotNull(data.BizLicensePic)) {
        Toast('请上传营业执照')
        return false
      }
      if (!this.testNotNull(data.ShopInsidePic)) {
        Toast('请上传店铺内景照')
        return false
      }
      if (!this.testNotNull(data.BankCardPic)) {
        Toast(`请上传${this.bankNameRemind}`)
        return false
      }
      if (!this.testNotNull(data.ProtocolIndexPic)) {
        Toast('请上传门头照')
        return false
      }
      if (!this.testPhone(data.telephone)) {
        Toast('请输入正确的手机号码')
        return false
      }
      if (!this.testEmail(data.email)) {
        Toast('请输入正确的邮箱地址')
        return false
      }
      return true
    },
    confirmAccountFormData (data) {
      if (!this.testNotNull(data.accountType.value)) {
        Toast('请选择账户类型')
        return false
      }
      return true
    },
    Submit () {
      if (!this.confirmBaseInfo(this.formData) || !this.confirmAccountFormData(this.formData)) return
      this.formModel.postForm().then(res => {
        const isOk = res
        if (isOk) {
          this.$bus.$emit('goToProgress')
          Toast('提交成功')
          this.$router.replace({ path: '/acquire-intro/progress' })
        } else {
          Toast('提交失败')
        }
      })
    },
    currentStep () {
      this.formModel.currentStep().then(res => {
        this.step = res.step
        if (this.step === this.enumObj.createForm) this.formModel.globalFromData()
      })
    },
    // 验证邮箱
    testEmail (value) {
      return /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)
    },
    // 验证手机号
    testPhone (value) {
      return /^1\d{10}$/.test(value)
    },
    // 验证姓名
    testName (value) {
      return value && value.length !== 0 && value.length < 12
    },
    // 验证不能为空
    testNotNull (value) {
      return value && value.length !== 0
    },
    toggleIntroduceDialog () {
      this.showDialog = !this.showDialog
    },
    // 清空门头照
    // TODO 清空函数
    emptyProtocolIndexPic () {
      this.formData.ProtocolIndexPic = ''
    },
    // 修改账户类型
    changeType () {
      this.emptyProtocolIndexPic()
    },
    // 处理图片路径为不带请求头，如：http://img.laoban100.com/xxx.png   => //img.laoban100.com/xxx.png
    _getModifyImgPath (url) {
      if (url) {
        return url.split(':')[1]
      }
      return ''
    },
    /**
     * 账户类型选择事件
     */
    handleConfirm (obj) {
      this.formData.accountType.value = obj.value
      this.accountTypeDialog = false
    },
    /**
     * 区域选择事件
     */
    handleAreaConfirm (obj) {
      this.formData.addressTexts = obj.map(item => item.name)
      this.formData.addressIds = obj.map(item => item.code)
      this.areaDialog = false
    }
  },
  computed: {
    cardNumberPlaceholder () {
      return this.formData.accountType.value === '企业' ? '企业营业执照填写企业对公账户' : '法人银行账户'
    },
    accountPlaceholder () {
      return this.formData.accountType.value === '企业' ? '请填写营业执照上面的企业名称' : '营业执照法人名称'
    },
    bankNameRemind () {
      return this.formData.accountType.value === '企业' ? '开户许可证' : '银行卡正面'
    },
    addressTitle () {
      return this.formData.accountType.value === '企业' ? '许可证开户地' : '银行卡开户地'
    },
    noticeText () {
      return this.formData.accountType.value === '企业' ? '身份证信息需与营业执照法人一致' : '身份证信息和银行卡开户人与营业执照法人一致'
    },
    isFailed () {
      return this.step === this.enumObj.recreateForm
    },
    hideProtocolIndexPic () {
      return this.formData.accountType.value === '企业'
    },
    showAddressValue () {
      return this.formData.addressTexts.length > 0 ? this.formData.addressTexts.join('/') : ''
    },
    areaCode () {
      const [, , third] = this.formData.addressIds
      return third
    }
  },
  watch: {
  }
}
</script>
